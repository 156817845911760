import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Accordion,
    AccordionPanelProps,
    AccordionTitleProps,
    SemanticShorthandCollection,
    TabPane,
} from "semantic-ui-react";
import {FeeChargeResult} from "../../../../Services/Pricing/Types";
import {formatBigDecimalAsCurrency, formatBigDecimalAsPercent} from "../../../../Utils/NumberUtils";
import {handleNullableProperty} from "../../../../Utils/TextUtils";
import UnitTestResultExpandShrink from "./UnitTestResultExpandShrink";
import UnitTestResultNextPeriodsPane from "./UnitTestResultNextPeriodsPane";

type UnitTestResultFeesPaneProps = {
    fees: FeeChargeResult[];
};

const UnitTestResultFeesPane = ({fees}: UnitTestResultFeesPaneProps) => {
    const {t} = useTranslation();
    const [activesIndexs, setActivesIndexs] = useState<number[] | undefined>();

    const handleAccordionTitleClick = (_: React.MouseEvent<HTMLDivElement>, data: AccordionTitleProps) => {
        const {index, active} = data;
        const newState = activesIndexs?.filter((i) => i !== index) ?? [];
        if (!active) newState.push(index as number);
        setActivesIndexs(newState);
    };

    const panels: SemanticShorthandCollection<AccordionPanelProps> = fees.map((fee, i) => {
        return {
            key: fee.code,
            title: `${fee.label} - ${fee.code}`,
            content: (
                <Accordion.Content active={activesIndexs?.includes(i)}>
                    <div className="amounts-pane">
                        <div data-cy="fee-informations">
                            <div className="row gap-1">
                                <div className="col" style={{minWidth: "8rem"}}>
                                    {t("Company code")}
                                </div>
                                <div className="col bold">{handleNullableProperty(fee.companyCode, t)}</div>
                            </div>
                            <div className="row gap-1">
                                <div className="col" style={{minWidth: "8rem"}}>
                                    {t("Tax code")}
                                </div>
                                <div className="col bold">{handleNullableProperty(fee.taxCode, t)}</div>
                            </div>
                            <div className="row gap-1">
                                <div className="col" style={{minWidth: "8rem"}}>
                                    {t("Tax rate")}
                                </div>
                                <div className="col bold">{formatBigDecimalAsPercent(fee.taxRate / 100, t)}</div>
                            </div>
                        </div>

                        <div data-cy="amounts">
                            <b>{t("Amounts")}</b>
                            <div className="row gap-1">
                                <div className="col">{t("Annual")}</div>
                                <div className="col">{t("TTC")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(fee.annualPremiumInclVat, t)}
                                </div>
                                <div className="col">{t("HT")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(fee.netAnnualPremium, t)}
                                </div>
                                <div className="col">{t("Taxes")}</div>
                                <div className="col left bold">{formatBigDecimalAsCurrency(fee.taxAnnual, t)}</div>
                            </div>

                            <div className="row gap-1">
                                <div className="col">{t("Splitted")}</div>
                                <div className="col">{t("TTC")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(fee.splittingPremiumInclVat, t)}
                                </div>
                                <div className="col">{t("HT")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(fee.netSplittingPremium, t)}
                                </div>
                                <div className="col">{t("Taxes")}</div>
                                <div className="col left bold">{formatBigDecimalAsCurrency(fee.taxSplitting, t)}</div>
                            </div>

                            <div className="row gap-1">
                                <div className="col">{t("Prorate")}</div>
                                <div className="col">{t("TTC")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(fee.proratePremiumInclVat, t)}
                                </div>
                                <div className="col">{t("HT")}</div>
                                <div className="col left bold">
                                    {formatBigDecimalAsCurrency(fee.netProratePremium, t)}
                                </div>
                                <div className="col">{t("Taxes")}</div>
                                <div className="col left bold">{formatBigDecimalAsCurrency(fee.taxProrate, t)}</div>
                            </div>
                        </div>
                    </div>

                    <UnitTestResultNextPeriodsPane nextPeriods={fee.nextPeriods} isFee={true} isNested={true} />
                </Accordion.Content>
            ),
        };
    });

    return (
        <TabPane data-cy="fees-pane">
            <UnitTestResultExpandShrink maxIndex={panels.length} setActivesIndexes={setActivesIndexs} />

            <Accordion
                styled
                exclusive={false}
                fluid
                panels={panels}
                activeIndex={activesIndexs}
                onTitleClick={handleAccordionTitleClick}
            />
        </TabPane>
    );
};

export default UnitTestResultFeesPane;
